import JoyTypography from '@mui/joy/Typography'
import typography from '../../theme/typography'

const Typography = ({ children, displayLarge, displayMedium, ...rest }) => {
  return (
    <JoyTypography
      {...rest}
      sx={{
        ...rest.sx,
        ...(typography[displayLarge ? 'display-lg' : displayMedium ? 'display-md' : rest.level] || null),
      }}
    >
      {children}
    </JoyTypography>
  )
}

export default Typography
