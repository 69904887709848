'use client'

import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { storyblokEditable } from '@storyblok/react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/joy/styles'
import InfoCircle from '@audioeye/ui-icons/Line/General/InfoCircle'
import Card from '@audioeye-marketing/ui/src/components/Card'
import Input from '@audioeye-marketing/ui/src/components/Input'
import Form from '@audioeye-marketing/ui/src/components/Form'
import FormControl from '@audioeye-marketing/ui/src/components/FormControl'
import FormLabel from '@audioeye-marketing/ui/src/components/FormLabel'
import FormHelperText from '@audioeye-marketing/ui/src/components/FormHelperText'
import Button from '@audioeye-marketing/ui/src/components/Button'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import trackEvent from '@audioeye-marketing/ui/src/lib/track-event'
import getLanguageTranslation from '@audioeye-marketing/ui/src/lib/get-language-translation'
import { useAppContext } from '@/providers/App.jsx'
import determineButtonColor from '@/lib/determine-button-color'

const ScannerForm = ({ blok, backgroundColor, horizontalForm }) => {
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [validationError, setValidationError] = useState('')
  const router = useRouter()
  const { currentLanguage } = useAppContext()
  const theme = useTheme()
  const lowerThanMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const buttonColor = determineButtonColor({ backgroundColor })

  const handleSubmit = (e) => {
    e.preventDefault()

    // eslint-disable-next-line -- can't get named capture groups to work
    const regex = /^((ftp|http|https):\/\/)?([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/i

    if (websiteUrl.match(regex)) {
      window.sessionStorage.setItem('scan_url', websiteUrl)
      const url = encodeURI(
        `https://www.audioeye.com/scan/results/?url=${websiteUrl}&partner=false&lang=${currentLanguage}`
      )

      trackEvent('Site Scan URL Submitted', {
        'Site URL': websiteUrl,
        'BOIA Scan': false,
      })

      router.push(url)
    } else setValidationError(getLanguageTranslation('validWebsiteAddress', currentLanguage))
  }

  const Wrapper = blok.card ? Card : Box

  const additionalProps = blok.card ? { size: 'lg' } : { sx: { pt: horizontalForm ? 0 : 4 } }

  const combinedSx = {
    ...additionalProps.sx,
    width: '100%',
    maxWidth: horizontalForm ? '100%' : '480px',
    border: 'none',
  }

  const combinedProps = {
    ...additionalProps,
    sx: combinedSx,
  }

  return (
    <Wrapper {...combinedProps} {...storyblokEditable(blok)}>
      <Form name="Site Scan URL" onSubmit={handleSubmit}>
        <FormControl error={Boolean(validationError)}>
          <Stack
            spacing={horizontalForm ? 0 : 1}
            flexDirection={horizontalForm ? 'row' : 'column'}
            gap={horizontalForm ? 3 : 0}
            alignItems="center"
            justifyContent="center"
          >
            <FormLabel>
              <Typography level="body-lg">{blok?.heading}</Typography>
            </FormLabel>
            <Box>
              {!lowerThanMediumScreen ? (
                <Input
                  sx={{
                    '--Input-decoratorChildHeight': '45px',
                    backgroundColor: 'transparent',
                  }}
                  name="website-url-desktop"
                  placeholder="www.website.com"
                  type="text"
                  required
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                  color={validationError ? 'danger' : buttonColor}
                  endDecorator={
                    <Button
                      variant="solid"
                      size="lg"
                      color={buttonColor}
                      type="submit"
                      sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    >
                      {blok?.button_text || 'Free Scan'}
                    </Button>
                  }
                />
              ) : (
                <>
                  <Input
                    sx={{
                      height: '45px',
                      mb: 2,
                      backgroundColor: 'transparent',
                    }}
                    name="website-url-mobile"
                    placeholder="www.website.com"
                    type="text"
                    required
                    value={websiteUrl}
                    onChange={(e) => setWebsiteUrl(e.target.value)}
                    color={validationError ? 'danger' : buttonColor}
                  />
                  <Button variant="solid" size="lg" color={buttonColor} type="submit" sx={{ width: '100%' }}>
                    {blok?.button_text || 'Free Scan'}
                  </Button>
                </>
              )}
            </Box>
            {Boolean(validationError) && (
              <FormHelperText>
                <InfoCircle />
                {validationError}
              </FormHelperText>
            )}
          </Stack>
        </FormControl>
      </Form>
    </Wrapper>
  )
}

export default ScannerForm
