'use client'

import React from 'react'
import JoyCheckbox from '@mui/joy/Checkbox'
import { useFormContext } from '../Form/FormProvider'
import trackEvent from '../../lib/track-event'

const Checkbox = React.forwardRef(({ onChange, onFocus, ...rest }, ref) => {
  const { formName, hubspotFormId } = useFormContext()

  return (
    <JoyCheckbox
      ref={ref}
      onChange={(e) => {
        onChange && onChange(e)

        trackEvent('Form Value Changed', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'checkbox',
          'Form Element Label': rest.label,
          'New Value': e.target.checked,
        })
      }}
      onFocus={(e) => {
        onFocus && onFocus(e)

        trackEvent('Form Value Focused', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'checkbox',
          'Form Element Label': rest.label,
          'Current Value': e.target.checked,
        })
      }}
      {...rest}
    />
  )
})

Checkbox.displayName = 'Checkbox'

export default Checkbox
