'use client'

/**
 * @param {string} email - email of the user to identify
 */
const identifyUser = async (email) => {
  if (!email) {
    return
  }

  // Heap
  if (window.heap) {
    window.heap.identify(email)
  }
}

export default identifyUser
