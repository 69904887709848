import React from 'react'

/**
 * @param {object} children - the children of the component
 * @return {string} - string containing the text from the children
 */
const getTextFromChildren = (children) => {
  let text = ''

  if (typeof children === 'string') {
    text = children
  } else if (React.isValidElement(children)) {
    text = children.props.children
  }

  return text
}

export default getTextFromChildren
