// * https://www.figma.com/design/k00f71eLDWU1h23Gm0o3Ck/AudioEye-Typography---Branding-update?node-id=1-29&node-type=canvas&t=IPiyrr7PFlGRnvdv-0
const typography = {
  h1: {
    fontSize: {
      xs: '2.5rem',
      sm: '2.5rem',
      md: '3.5rem',
    },
    lineHeight: {
      xs: '2.625rem',
      sm: '2.625rem',
      md: '3.625rem',
    },
    fontWeight: '600',
  },
  h2: {
    fontSize: {
      xs: '2.25rem',
      sm: '2.25rem',
      md: '3rem',
    },
    lineHeight: {
      xs: '2.625rem',
      sm: '2.625rem',
      md: '3.625rem',
    },
    fontWeight: '700',
  },
  h3: {
    fontSize: {
      xs: '1.75rem',
      sm: '1.75rem',
      md: '2.25rem',
    },
    lineHeight: {
      xs: '2.125rem',
      sm: '2.125rem',
      md: '2.75rem',
    },
    fontWeight: '700',
  },
  h4: {
    fontSize: {
      xs: '1.5rem',
      sm: '1.5rem',
      md: '1.75rem',
    },
    lineHeight: {
      xs: '1.75rem',
      sm: '1.75rem',
      md: '2rem',
    },
    fontWeight: '600',
  },
  'body-lg': {
    fontSize: {
      xs: '1.125rem',
      sm: '1.125rem',
      md: '1.125rem',
    },
    lineHeight: {
      xs: '1.75rem',
      sm: '1.75rem',
      md: '1.75rem',
    },
  },
  'body-md': {
    fontSize: {
      xs: '1rem',
      sm: '1rem',
      md: '1rem',
    },
    lineHeight: {
      xs: '1.5rem',
      sm: '1.5rem',
      md: '1.5rem',
    },
  },
  'body-sm': {
    fontSize: {
      xs: '.875rem',
      sm: '.875rem',
      md: '.875rem',
    },
    lineHeight: {
      xs: '1.25rem',
      sm: '1.25rem',
      md: '1.25rem',
    },
  },
  'body-xs': {
    fontSize: {
      xs: '.75rem',
      sm: '.75rem',
      md: '.75rem',
    },
    lineHeight: {
      xs: '1rem',
      sm: '1rem',
      md: '1rem',
    },
  },
  'label-sm': {
    fontSize: {
      xs: '0.875rem',
      sm: '0.875rem',
      md: '0.875rem',
    },
    lineHeight: {
      xs: '1.25rem',
      sm: '1.25rem',
      md: '1.25rem',
    },
    fontWeight: '500',
  },
  eyebrow: {
    fontSize: {
      xs: '.875rem',
      sm: '.875rem',
      md: '.875rem',
    },
    lineHeight: {
      xs: '1.25rem',
      sm: '1.25rem',
      md: '1.25rem',
    },
    fontWeight: '500',
  },
  // * these are not a new token in Joy UI because we need the element to stay the same but just be different font size (aka h1 element with a different font size)
  'display-lg': {
    fontSize: {
      xs: '3rem',
      sm: '3rem',
      md: '4rem',
    },
    lineHeight: {
      xs: '3.125rem',
      sm: '3.125rem',
      md: '4.25rem',
    },
    fontWeight: '600',
  },
  'display-md': {
    fontSize: {
      xs: '1.75rem',
      sm: '1.75rem',
      md: '2.25rem',
    },
    lineHeight: {
      xs: '2.125rem',
      sm: '2.125rem',
      md: '2.75rem',
    },
    fontWeight: '500',
  },
}

export default typography
