import React from 'react'
import JoyModalDialog from '@mui/joy/ModalDialog'

const ModalDialog = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <JoyModalDialog ref={ref} {...rest}>
      {children}
    </JoyModalDialog>
  )
})

ModalDialog.displayName = 'ModalDialog'

export default ModalDialog
