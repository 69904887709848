import Stack from '../Stack'

const Fieldset = ({ children, ...rest }) => {
  return (
    <Stack
      component="fieldset"
      gap={2}
      flexDirection={{ xs: 'column', lg: 'row' }}
      alignItems="baseline"
      sx={{
        border: 'none !important',
        padding: '0 !important',
        margin: '0 !important',
        width: '100%',
        flexWrap: 'wrap',
      }}
      {...rest}
    >
      {children}
    </Stack>
  )
}

export default Fieldset
