'use client'

import JoyButton from '@mui/joy/Button'
import trackEvent from '../../lib/track-event'

const Button = ({ children, fullWidth, ...rest }) => {
  const sxProps = {
    ...rest.sx,
  }

  Boolean(fullWidth) && (sxProps.width = { xs: '100%', md: 'auto' })

  return (
    <JoyButton
      {...rest}
      sx={sxProps}
      onClick={() => {
        Boolean(rest.onClick) && rest.onClick()

        trackEvent('Button Clicked', {
          'Button Text': children,
          'Button Type': rest.type,
          Destination: rest.href,
        })
      }}
    >
      {children}
    </JoyButton>
  )
}

export default Button
