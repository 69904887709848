import Stack from '../Stack'
import Typography from '../Typography'
import Skeleton from '../Skeleton'
import Box from '../Box'

const FormLoading = ({ ...rest }) => {
  return (
    <Stack spacing={2} {...rest}>
      <Typography level="body-lg">
        <Skeleton variant="text" width="20%" />
      </Typography>

      <Stack flexDirection="row" gap={2}>
        <Stack flexDirection="column" sx={{ width: '50%' }}>
          <Typography level="body-lg">
            <Skeleton variant="text" width="20%" />
          </Typography>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Stack>
        <Stack flexDirection="column" sx={{ width: '50%' }}>
          <Typography level="body-lg">
            <Skeleton variant="text" width="20%" />
          </Typography>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Stack>
      </Stack>

      <Stack flexDirection="row" gap={2}>
        <Stack flexDirection="column" sx={{ width: '50%' }}>
          <Typography level="body-lg">
            <Skeleton variant="text" width="20%" />
          </Typography>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Stack>
        <Stack flexDirection="column" sx={{ width: '50%' }}>
          <Typography level="body-lg">
            <Skeleton variant="text" width="20%" />
          </Typography>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Stack>
      </Stack>

      <Box>
        <Typography level="body-lg">
          <Skeleton variant="text" width="10%" />
        </Typography>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>

      <Box>
        <Typography level="body-lg">
          <Skeleton variant="text" width="10%" />
        </Typography>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    </Stack>
  )
}

export default FormLoading
