'use client'

import React from 'react'
import JoySelect from '@mui/joy/Select'
import { useFormContext } from '../Form/FormProvider'
import trackEvent from '../../lib/track-event'

const Select = React.forwardRef(({ children, onChange, onFocus, ...rest }, ref) => {
  const { formName, hubspotFormId } = useFormContext()

  return (
    <JoySelect
      ref={ref}
      onChange={(e, joyValue) => {
        onChange && onChange(e, joyValue)

        trackEvent('Form Value Changed', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'select',
          'New Value': joyValue, // must use joyValue because joy renders an input for the select element thats not targetable by e.target
        })
      }}
      onFocus={(e, joyValue) => {
        onFocus && onFocus(e, joyValue)

        trackEvent('Form Value Focused', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'select',
          'Current Value': joyValue, // must use joyValue because joy renders an input for the select element thats not targetable by e.target
        })
      }}
      {...rest}
    >
      {children}
    </JoySelect>
  )
})

Select.displayName = 'Select'

export default Select
