'use client'

import React from 'react'
import JoyTextarea from '@mui/joy/Textarea'
import { useFormContext } from '../Form'
import trackEvent from '../../lib/track-event'

const Textarea = React.forwardRef(({ children, onBlur, onFocus, ...rest }, ref) => {
  const { formName, hubspotFormId } = useFormContext()

  return (
    <JoyTextarea
      onBlur={(e) => {
        onBlur && onBlur(e)

        trackEvent('Form Value Changed', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'textarea',
          'New Value': e.target.value,
        })
      }}
      onFocus={(e) => {
        onFocus && onFocus(e)

        trackEvent('Form Value Focused', {
          'Form Name': formName,
          'Form Hubspot ID': hubspotFormId,
          'Form Element Name': rest.name,
          'Form Element Type': 'textarea',
          'Current Value': e.target.value,
        })
      }}
      ref={ref}
      {...rest}
    >
      {children}
    </JoyTextarea>
  )
})

Textarea.displayName = 'Textarea'

export default Textarea
