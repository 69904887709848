import JoyCard from '@mui/joy/Card'

const Card = ({ children, noBorderRadius, ...rest }) => {
  const sxProps = {
    ...rest.sx,
  }

  Boolean(noBorderRadius) && (sxProps['--Card-radius'] = '0px')

  return (
    <JoyCard {...rest} sx={sxProps}>
      {children}
    </JoyCard>
  )
}

export default Card
