'use client'

import { useForm, FormProvider } from 'react-hook-form'
import { FormProvider as UIFormProvider } from '../Form/FormProvider'

const ReactHookForm = ({
  name,
  onSubmit = () => {},
  onInvalidSubmit = () => {},
  hubspotFormId,
  language,
  children,
  ...rest
}) => {
  const methods = useForm()

  return (
    <UIFormProvider formName={name} hubspotFormId={hubspotFormId} language={language}>
      <FormProvider {...methods}>
        <form name={name} {...rest} onSubmit={methods.handleSubmit(onSubmit, onInvalidSubmit)}>
          {children}
        </form>
      </FormProvider>
    </UIFormProvider>
  )
}

export default ReactHookForm
