'use client'

import { useQuery } from '@tanstack/react-query'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'
import Chip from '@audioeye-marketing/ui/src/components/Chip'
import getLanguageTranslation from '@audioeye-marketing/ui/src/lib/get-language-translation'
import Loading01 from '@audioeye/ui-icons/Line/General/Loading01'
import AlertOctagon from '@audioeye/ui-icons/Line/Alerts/AlertOctagon'
import AlertTriangle from '@audioeye/ui-icons/Line/Alerts/AlertTriangle'
import ClockFastForward from '@audioeye/ui-icons/Line/Time/ClockFastForward'
import CheckCircle from '@audioeye/ui-icons/Line/General/CheckCircle'
import { useAppContext } from '@/providers/App.jsx'

const AudioEyeSystemStatus = ({ alignItems, justifyContent }) => {
  const { currentLanguage } = useAppContext()

  const { isPending, error, data } = useQuery({
    queryKey: ['audioeyeSystemStatus'],
    queryFn: async () => await fetch('/api/platform/status').then(async (res) => await res.json()),
    staleTime: 1000 * 60 * 5, // Data will be fresh for 5 mins
  })

  const translations = getLanguageTranslation('platformStatus', currentLanguage)

  const status = data?.platformStatus?.status?.indicator
  let startDecorator
  let description
  let color

  if (isPending) {
    startDecorator = <Loading01 />
    description = translations.loading || 'Loading...'
    color = 'neutral'
  } else if (error) {
    startDecorator = <AlertOctagon />
    description = translations.statusUnavailable || 'Status Unavailable'
    color = 'danger'
  } else if (status === 'major') {
    startDecorator = <AlertOctagon />
    description = translations.majorOutage || 'Major Outage'
    color = 'danger'
  } else if (status === 'critical') {
    startDecorator = <AlertTriangle />
    description = translations.temporaryOutage || 'Temporary Outage'
    color = 'danger'
  } else if (status === 'minor') {
    startDecorator = <ClockFastForward />
    description = translations.minorOperationDelays || 'Minor Operation Delays'
    color = 'warning'
  } else {
    startDecorator = <CheckCircle />
    description = translations.allSystemsOperational || 'All Systems Operational'
    color = 'success'
  }

  return (
    <Stack alignItems={alignItems || 'center'} justifyContent={justifyContent} spacing={1.25}>
      <Typography level="label-sm">{translations.systemStatus || 'System Status'}:</Typography>
      <Chip
        startDecorator={startDecorator}
        slotProps={{ action: { component: 'a', href: 'https://status.audioeye.com/', target: '_blank' } }}
        variant="soft"
        color={color}
      >
        {description}
      </Chip>
    </Stack>
  )
}

export default AudioEyeSystemStatus
