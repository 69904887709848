// * https://www.figma.com/design/jSpJcZIZcDdlpX194qUwuH/%F0%9F%95%B8%EF%B8%8F-Marketing-Website-Components?node-id=1197-61313&node-type=frame&t=b8hU58KzoGYfbUwY-0
const colors = {
  primary: {
    50: '#F7F5FF',
    100: '#EDEBFE',
    200: '#DCD6FF',
    300: '#BCB2FF',
    400: '#A18FFF',
    500: '#8055F7',
    600: '#602ECC',
    700: '#48189A',
    800: '#320A6C',
    900: '#1D0240',
  },
  secondary: {
    50: '#EEFCFC',
    100: '#D6F5F5',
    200: '#ADEBE7',
    300: '#7DD8D5',
    400: '#3CB4B0',
    500: '#078381',
    600: '#005F61',
    700: '#004447',
    800: '#083235',
    900: '#022327',
  },
  tertiary: {
    50: '#FFF5FA',
    100: '#FFE0EA',
    200: '#FFB2C6',
    300: '#FE8BA6',
    400: '#F45D7E',
    500: '#D83158',
    600: '#A71B3C',
    700: '#7E112E',
    800: '#5F0723',
    900: '#380017',
  },
  neutral: {
    50: '#F7F8FD',
    100: '#ECEFF8',
    200: '#D9DDED',
    300: '#B0B7CF',
    400: '#8D95B0',
    500: '#6C758E',
    600: '#4A5169',
    700: '#363C4F',
    800: '#242938',
    900: '#191D29',
  },
  info: {
    50: '#F0F5FF',
    100: '#DBE8FF',
    200: '#B8D2FF',
    300: '#94BBFF',
    400: '#6B9FFF',
    500: '#356EE9',
    600: '#1B4DC0',
    700: '#0F328A',
    800: '#012260',
    900: '#011737',
  },
  danger: {
    50: '#FFF3F0',
    100: '#FFDFD6',
    200: '#FFBAA8',
    300: '#FF9D85',
    400: '#FB7F60',
    500: '#CF3F17',
    600: '#A62D08',
    700: '#7F1F00',
    800: '#521600',
    900: '#2E0800',
  },
  success: {
    50: '#EDFDF5',
    100: '#DBFAE9',
    200: '#BBF7D5',
    300: '#84E1AC',
    400: '#46BE7A',
    500: '#0E864E',
    600: '#0E6235',
    700: '#0B4726',
    800: '#062D17',
    900: '#021D0F',
  },
  warning: {
    50: '#FFF6E5',
    100: '#FFEBC2',
    200: '#FFD485',
    300: '#F6BB55',
    400: '#D99726',
    500: '#A16C00',
    600: '#805300',
    700: '#5C3C00',
    800: '#3D2900',
    900: '#241900',
  },
  light: {
    50: '#FFFFFF',
    100: 'rgba(255, 255, 255, 0.9)',
    200: 'rgba(255, 255, 255, 0.8)',
    300: 'rgba(255, 255, 255, 0.7)',
    400: 'rgba(255, 255, 255, 0.6)',
    500: 'rgba(255, 255, 255, 0.5)',
    600: 'rgba(255, 255, 255, 0.4)',
    700: 'rgba(255, 255, 255, 0.3)',
    800: 'rgba(255, 255, 255, 0.2)',
    900: 'rgba(255, 255, 255, 0.1)',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
}

export default colors
