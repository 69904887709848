/**
 * @param {string} key - key matching the data wanted from the languageTranslations object
 * @param {string} language - language to pull the translation from
 * @return {string} - translation of the key in the specified language (english default)
 */
const getLanguageTranslation = (key, language) => {
  const languageTranslations = {
    en: {
      trialHeading: 'Start Free 14-Day Trial',
      completeAllRequiredFields: 'Please complete all required fields',
      fieldRequired: 'This field is required',
      invalidEmailFormat: 'Invalid email format',
      emailsFromDomainNotAllowed: 'Emails from {domain} are not allowed',
      numberNotInRange: 'The number you entered is not in range',
      numbersOnly: 'Only numbers are valid',
      numberLessThan: 'Please enter a number less than or equal to {number}',
      numberGreaterThan: 'Please enter a number greater than or equal to {number}',
      invalidWebsite: 'Invalid website format. Please enter a URL like https://example.com or www.example.com',
      passwordLabel: 'Password',
      passwordNotValid: 'Password does not meet requirements',
      validWebsiteAddress: 'Please use a valid website address',
      createAccount: "By creating an account, you're agreeing to our",
      agencyTerms: {
        href: '/reseller-program-agreement/',
        text: 'Reseller Terms and Conditions',
      },
      termsOfService: {
        href: '/terms-of-service/',
        text: 'Terms of Service',
      },
      privacyPolicy: {
        href: '/privacy-policy/',
        text: 'Policies',
      },
      and: 'and',
      emailCommunications: '',
      platformStatus: {
        systemStatus: 'System Status',
        loading: 'Loading...',
        statusUnavailable: 'Status Unavailable',
        majorOutage: 'Major Outage',
        temporaryOutage: 'Temporary Outage',
        minorOperationDelays: 'Minor Operation Delays',
        allSystemsOperational: 'All Systems Operational',
      },
    },
    de: {
      trialHeading: 'Kostenlose 14-tägige Testphase starten',
      completeAllRequiredFields: 'Bitte füllen Sie alle Pflichtfelder aus',
      fieldRequired: 'Bitte füllen Sie dieses Pflichtfeld aus',
      invalidEmailFormat: 'E-Mail muss korrekt formatiert sein',
      emailsFromDomainNotAllowed:
        'Bitte geben Sie eine andere E-Mail-Adresse ein. Dieses Formular akzeptiert keine Adressen von {domain}',
      numberNotInRange: 'Die eingegebene Zahl ist nicht zulässig',
      numbersOnly: 'Es sind nur Zahlen gültig',
      numberLessThan: 'Bitte geben Sie eine Zahl kleiner oder gleich 500 ein',
      numberGreaterThan: 'Bitte geben Sie eine Zahl ein, die größer oder gleich {number} ist.',
      invalidWebsite:
        'Ungültiges Website-Format. Bitte geben Sie eine URL wie https://example.com oder www.example.com ein',
      passwordLabel: 'Passwort',
      passwordNotValid: 'Passwort entspricht nicht der Anforderungen',
      validWebsiteAddress: 'Bitte verwenden Sie eine gültige Website-Adresse',
      createAccount: 'Indem Sie ein Konto erstellen sie erklären sich mit unseren',
      agencyTerms: {
        href: '/reseller-program-agreement/',
        text: 'Reseller-Nutzungsbedingungen',
      },
      termsOfService: {
        href: '/de/terms-of-service/',
        text: 'Nutzungsbedingungen',
      },
      privacyPolicy: {
        href: '/de/privacy-policy/',
        text: 'Datenrichtlinien einverstanden',
      },
      and: 'und',
      emailCommunications:
        'Da wir unser Unternehmen in Europa noch ausbauen, wird unser Vertriebsteam mit Ihnen auf Englisch kommunizieren. Vielen Dank für Ihre Geduld, während unser Unternehmen noch wächst.',
      platformStatus: {
        systemStatus: 'Systemstatus',
        loading: 'Laden...',
        statusUnavailable: 'Status Nicht verfügbar',
        majorOutage: 'Größerer Ausfall',
        temporaryOutage: 'Vorübergehender Ausfall',
        minorOperationDelays: 'Kleinere Betriebsverzögerungen',
        allSystemsOperational: 'Alle Systeme betriebsbereit',
      },
    },
    fr: {
      trialHeading: "Commencez l'essai gratuit de 14 jours",
      completeAllRequiredFields: 'Veuillez remplir tous les champs obligatoires',
      fieldRequired: 'Veuillez remplir ce champ obligatoire',
      invalidEmailFormat: "L'adresse e-mail doit être indiquée dans un format approprié",
      emailsFromDomainNotAllowed:
        'Saisissez une autre adresse e-mail. Les adresses de type {domain} ne peuvent pas être saisies sur ce formulaire',
      numberNotInRange: "Le numéro que vous avez saisi n'est pas dans la plage",
      numbersOnly: 'Seuls les chiffres sont valables',
      numberLessThan: 'Veuillez saisir un nombre inférieur ou égal à {number}',
      numberGreaterThan: 'Veuillez saisir un nombre supérieur ou égal à {number}',
      invalidWebsite:
        'Format de site Web invalide. Veuillez saisir une URL telle que https://example.com ou www.example.com',
      passwordLabel: 'Mot de passe',
      passwordNotValid: 'Le mot de passe ne répond pas aux exigences',
      validWebsiteAddress: 'Veuillez utiliser une adresse de site Web valide',
      createAccount: 'En créant un compte, vous acceptez nos',
      agencyTerms: {
        href: '/reseller-program-agreement/',
        text: 'conditions de revendeur',
      },
      termsOfService: {
        href: '/fr/terms-of-service/',
        text: 'nos conditions de service',
      },
      privacyPolicy: {
        href: '/fr/privacy-policy/',
        text: 'nos politiques en matière de données.',
      },
      and: 'et',
      emailCommunications:
        'Étant donné que nous étendons notre entreprise en Europe, les communications de notre équipe commerciale se feront en anglais. Nous vous remercions de votre patience pendant que notre entreprise se développe.',
      platformStatus: {
        systemStatus: 'État du système',
        loading: 'Chargement...',
        statusUnavailable: 'Statut indisponible',
        majorOutage: 'Panne majeure',
        temporaryOutage: 'Panne temporaire',
        minorOperationDelays: 'Retards mineurs dans les opérations',
        allSystemsOperational: 'Tous les systèmes sont opérationnels',
      },
    },
    es: {
      trialHeading: 'Comenzar prueba gratuita de 14 días',
      completeAllRequiredFields: 'Por favor complete todos los campos requeridos',
      fieldRequired: 'Rellena este campo obligatorio',
      invalidEmailFormat: 'La dirección de correo debe tener un formato correcto',
      emailsFromDomainNotAllowed:
        'Introduce una dirección de correo diferente. Este formulario no acepta direcciones de {domain}',
      numberNotInRange: 'El número que has introducido no está dentro del rango',
      numbersOnly: 'Sólo los números son válidos',
      numberLessThan: 'Por favor ingrese un número menor o igual a {number}',
      numberGreaterThan: 'Por favor ingrese un número mayor o igual a {number}',
      invalidWebsite: 'Formato de sitio web no válido. Ingrese una URL como https://example.com o www.example.com',
      passwordLabel: 'Contraseña',
      passwordNotValid: 'La contraseña no cumple los requisitos',
      validWebsiteAddress: 'Utilice una dirección de sitio web válida',
      createAccount: 'Al crear una cuenta, acepta nuestros',
      agencyTerms: {
        href: '/reseller-program-agreement/',
        text: 'Términos y condiciones de revendedor',
      },
      termsOfService: {
        href: '/es/terms-of-service/',
        text: 'términos de servicio',
      },
      privacyPolicy: {
        href: '/es/privacy-policy/',
        text: 'Políticas de datos',
      },
      and: 'y',
      emailCommunications:
        'Como todavía estamos expandiendo nuestro negocio en Europa, nuestro equipo de ventas se comunicará con usted en inglés. Gracias por su paciencia mientras nuestra empresa continúa creciendo.',
      platformStatus: {
        systemStatus: 'Estado del sistema',
        loading: 'Cargando...',
        statusUnavailable: 'Estado no disponible',
        majorOutage: 'Interrupción importante',
        temporaryOutage: 'Interrupción temporal',
        minorOperationDelays: 'Retrasos menores en la operación',
        allSystemsOperational: 'Todos los sistemas operativos',
      },
    },
    it: {
      trialHeading: 'Avvia la prova gratuita di 14 giorni',
      completeAllRequiredFields: 'Si prega di completare tutti i campi obbligatori',
      fieldRequired: 'Compila il campo richiesto',
      invalidEmailFormat: "L'e-mail deve essere formattata correttamente",
      emailsFromDomainNotAllowed: 'Inserisci un indirizzo e-mail diverso. Il modulo non accetta indirizzi da {domain}',
      numberNotInRange: 'Il numero inserito non rientra nei valori consentiti',
      numbersOnly: 'Sono validi solo i numeri',
      numberLessThan: 'Inserisci un numero inferiore o uguale a {number}',
      numberGreaterThan: 'Inserisci un numero maggiore o uguale a {number}',
      invalidWebsite: 'Formato del sito web non valido. Inserisci un URL come https://example.com o www.example.com',
      passwordLabel: 'Password',
      passwordNotValid: 'La password non soddisfa i requisiti',
      validWebsiteAddress: 'Si prega di utilizzare un indirizzo web valido',
      createAccount: 'Creando un account, accetti i nostri',
      agencyTerms: {
        href: '/reseller-program-agreement/',
        text: 'Termini e condizioni per rivenditori',
      },
      termsOfService: {
        href: '/it/terms-of-service/',
        text: 'Termini di servizio',
      },
      privacyPolicy: {
        href: '/it/privacy-policy/',
        text: 'Norme sui dati',
      },
      and: 'e',
      emailCommunications:
        'Poiché stiamo ancora espandendo la nostra attività in Europa, il nostro team di vendita comunicherà con te in inglese. Grazie per la pazienza mentre la nostra azienda continua a crescere.',
      platformStatus: {
        systemStatus: 'Stato del sistema',
        loading: 'Caricamento...',
        statusUnavailable: 'Stato non disponibile',
        majorOutage: 'Interruzione importante',
        temporaryOutage: 'Interruzione temporanea',
        minorOperationDelays: 'Ritardi minori nelle operazioni',
        allSystemsOperational: 'Tutti i sistemi operativi',
      },
    },
  }

  if (!languageTranslations[language] || !languageTranslations[language][key]) {
    return languageTranslations.en[key]
  }

  return languageTranslations[language][key]
}

export default getLanguageTranslation
