'use client'

import JoyAccordionSummary from '@mui/joy/AccordionSummary'
import trackEvent from '../../lib/track-event'
import getTextFromChildren from '../../lib/get-text-from-children'

const AccordionSummary = ({ children, ...rest }) => {
  return (
    <JoyAccordionSummary
      onClick={(e) => {
        trackEvent(`Accordion Toggled`, {
          'Accordion Text': getTextFromChildren(children),
          'Accordion Open': !e.currentTarget.classList.contains('Mui-expanded'),
        })
      }}
      {...rest}
    >
      {children}
    </JoyAccordionSummary>
  )
}

export default AccordionSummary
