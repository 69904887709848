'use client'

import NextLink from 'next/link'
import JoyIconButton from '@mui/joy/IconButton'
import trackEvent from '../../lib/track-event'

const IconButton = ({ children, ...rest }) => {
  return (
    <JoyIconButton
      {...rest}
      component={NextLink}
      onClick={() => {
        Boolean(rest.onClick) && rest.onClick()

        trackEvent('Button Clicked', {
          'Button Text': children,
          'Button Type': rest.type,
          Destination: rest.href,
        })
      }}
    >
      {children}
    </JoyIconButton>
  )
}

export default IconButton
