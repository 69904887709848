'use client'

import { useState } from 'react'
import Button from '@audioeye-marketing/ui/src/components/Button'
import Box from '@audioeye-marketing/ui/src/components/Box'
import Input from '@audioeye-marketing/ui/src/components/Input'
import Form from '@audioeye-marketing/ui/src/components/Form'
import FormControl from '@audioeye-marketing/ui/src/components/FormControl'
import FormLabel from '@audioeye-marketing/ui/src/components/FormLabel'
import FormHelperText from '@audioeye-marketing/ui/src/components/FormHelperText'
import Stack from '@audioeye-marketing/ui/src/components/Stack'
import Typography from '@audioeye-marketing/ui/src/components/Typography'

const PasswordProtectedPage = ({ cmsPassword, storyId }) => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [validationError, setValidationError] = useState('')

  const handlePasswordSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    if (password === cmsPassword) {
      setValidationError('')
      await fetch(`/api/auth/set/${storyId}`, {
        method: 'POST',
      }).then(() => {
        window.location.reload()
      })
    } else {
      setPassword('')
      setValidationError('Incorrect password')
      setLoading(false)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box sx={{ width: '80%', maxWidth: '600px' }}>
        <Form name="Site Scan URL" onSubmit={handlePasswordSubmit}>
          <FormControl error={Boolean(validationError)}>
            <Stack spacing={1}>
              <FormLabel>
                <Typography>
                  <strong>Password</strong>
                </Typography>
              </FormLabel>
              <Box>
                <Input
                  sx={{ '--Input-decoratorChildHeight': '45px' }}
                  name="password"
                  placeholder="Enter password"
                  type="text"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endDecorator={
                    <Button
                      variant="solid"
                      size="lg"
                      color="primary"
                      type="submit"
                      sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                      loading={loading}
                    >
                      Submit
                    </Button>
                  }
                />
              </Box>
              {Boolean(validationError) && <FormHelperText>{validationError}</FormHelperText>}
            </Stack>
          </FormControl>
        </Form>
      </Box>
    </Box>
  )
}

export default PasswordProtectedPage
