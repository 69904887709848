'use client'

import NextImage from 'next/image'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import { useTheme } from '@mui/joy/styles'
import AspectRatio from '@audioeye-marketing/ui/src/components/AspectRatio'
import Box from '@audioeye-marketing/ui/src/components/Box'

const ImageWrapper = ({ imageDimensions, children, theme, ...rest }) => {
  return (
    <Box sx={{ width: '100%', maxWidth: `${imageDimensions?.maxWidth}px` }}>
      <AspectRatio
        variant="plain"
        ratio={`${imageDimensions?.width}/${imageDimensions?.height}`}
        sx={{
          position: 'relative',
          zIndex: 3,
          width: '100%',
          '&:focus': {
            outline: `${theme?.vars?.focus?.thickness} solid ${theme?.vars?.palette?.primary['500']}`,
            borderRadius: `${theme?.vars?.radius?.sm}`,
          },
        }}
        {...rest}
      >
        {children}
      </AspectRatio>
    </Box>
  )
}

const Image = ({ blok, maxWidth, imagePriority }) => {
  const theme = useTheme()
  const triggerBlok = blok?.trigger?.[0]
  const trigger = triggerBlok?.trigger?.[0]

  // * https://www.storyblok.com/faq/image-dimensions-assets-js
  const originalWidth = parseInt(blok?.image?.filename?.split('/')[5]?.split('x')[0])
  const originalHeight = parseInt(blok?.image?.filename?.split('/')[5]?.split('x')[1])
  const calculatedMaxWidth = maxWidth ? maxWidth : originalWidth < 600 ? originalWidth : 600

  const imageDimensions = {
    width: originalWidth,
    height: originalHeight,
    maxWidth: calculatedMaxWidth,
  }

  if (calculatedMaxWidth && originalWidth > calculatedMaxWidth) {
    const aspectRatio = originalHeight / originalWidth
    imageDimensions.width = calculatedMaxWidth
    imageDimensions.height = Math.round(calculatedMaxWidth * aspectRatio)
  }

  if (!blok?.image?.filename) {
    return null
  }

  const FinalImage = (
    <ImageWrapper {...storyblokEditable(blok)} imageDimensions={imageDimensions} theme={theme}>
      <NextImage
        src={blok?.image?.filename}
        alt={blok?.image?.alt}
        style={trigger ? { objectFit: 'contain', cursor: 'pointer' } : { objectFit: 'contain' }}
        width={imageDimensions.width}
        height={imageDimensions.height}
        priority={imagePriority}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw"
        // * https://www.storyblok.com/docs/image-service
        loader={({ src }) => `${src}/m/${imageDimensions.width}x0/filters:quality(100)`}
      />
    </ImageWrapper>
  )

  if (triggerBlok) {
    return <StoryblokServerComponent blok={triggerBlok} triggerComponent={FinalImage} />
  }

  return FinalImage
}

export default Image
