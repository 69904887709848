'use client'

import posthog from 'posthog-js'

/**
 * @param {string} eventName - the name of the event that is shown in the analytics dashboard
 * @param {object} data - object containing any meta data about the event
 */
const trackEvent = (eventName, data) => {
  if (typeof window !== 'undefined') {
    // GTM
    if (window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ...data,
      })
    }

    // Heap
    if (window.heap) {
      window.heap.track(eventName, {
        ...data,
      })
    }

    // PostHog
    posthog.capture(eventName, { ...data })
  }
}

export default trackEvent
