'use client'

import { useEffect } from 'react'
import trackEvent from '../../lib/track-event'
import { FormProvider as UIFormProvider } from './FormProvider'

const Form = ({ name, children, ...rest }) => {
  useEffect(() => {
    trackEvent(`Form Viewed`, {
      'Form Name': name,
    })
  }, [name])

  return (
    <UIFormProvider formName={name}>
      <form name={name} {...rest}>
        {children}
      </form>
    </UIFormProvider>
  )
}

export default Form
