'use client'

import React, { useState } from 'react'
import { StoryblokServerComponent, storyblokEditable } from '@storyblok/react/rsc'
import UIModal from '@audioeye-marketing/ui/src/components/Modal'
import ModalDialog from '@audioeye-marketing/ui/src/components/ModalDialog'
import DialogContent from '@audioeye-marketing/ui/src/components/DialogContent'
import ModalClose from '@audioeye-marketing/ui/src/components/ModalClose'

const Modal = ({ blok, trigger }) => {
  const [open, setOpen] = useState(false)
  const clonedTrigger = React.cloneElement(trigger, {
    onClick: () => {
      setOpen(true)
    },
    ...storyblokEditable,
    // Next two props are needed for when images are the trigger
    onKeyDown: (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        setOpen(true)
      }
    },
    tabIndex: 0,
  })

  return (
    <>
      {clonedTrigger}
      <UIModal {...storyblokEditable(blok)} open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          aria-label={blok?.content[0]?.component}
          size="lg"
          layout="center"
          sx={{
            width: {
              xs: '90%',
              lg: blok?.content[0]?.component === 'Form' ? '50%' : '80%',
            },
            backgroundColor: 'white',
          }}
        >
          <ModalClose variant="soft" aria-label="close dialog" />
          <DialogContent sx={{ mt: 4 }}>
            {blok?.content[0] ? <StoryblokServerComponent blok={blok?.content[0]} /> : null}
          </DialogContent>
        </ModalDialog>
      </UIModal>
    </>
  )
}

export default Modal
